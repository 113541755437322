import {
	createLengthValidator,
	createRequiredValidator,
	createEmailValidator,
	validateRepeatPassword,
	formValidation,
} from './validate'

const errorMessageClass = 'dispatch-error-message' // class for message container

const forms = document.querySelectorAll('form[data-dispatch-registration]')

const RegisterTrialResult = {
	Success: 0,
	RepeatActivationEmail: 1,
}

const getConfirmRegistrationMsg = () => 'Пожалуйста, подтвердите регистрацию по e-mail'
const getResendActivationEmailMsg = () => 'Письмо с запросом активации выслано повторно.'
const	getRepeatActivationMsg = () => `Учетная запись уже создана, но не была активирована,
	потому что адрес e-mail этой записи не был подтвержден.`
const getCheckEmailReceptionMsg = (email) => `Пожалуйста, разрешите прием почты с адреса ${email}
	и проверьте свой почтовый ящик - возможно, письма с запросами активации попадают в спам.`
const getUserExistsMsg = (user) => `Пользователь '${user}' существует`

let passwordModel = '' // NOTE: password model for validation

const { validateInput, validateForm } = formValidation({
	fullname: [
		createRequiredValidator(),
		createLengthValidator({ min: 3, max: 256 }),
	],
	company: [
		createLengthValidator({ max: 256 })
	],
	email: [
		createRequiredValidator(),
		createLengthValidator({ min: 5, max: 256 }),
		createEmailValidator(),
	],
	password: [
		createRequiredValidator(),
		createLengthValidator({ min: 5, max: 32 }),
	],
	passwordR: [
		createRequiredValidator(),
		createLengthValidator({ min: 5, max: 32 }),
		(input) => validateRepeatPassword(input, passwordModel),
	],
})

const apiVerPrefix = 'api1.'

const getRequestId = (() => {
		let id = 0
		return () => id++
})()

async function send(url, method, params) {
	const response = await fetch(`${url}/rpc`, {
		method: 'post',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			id: getRequestId(),
			jsonrpc: '2.0',
			buildVersion: '*',
			method: `${apiVerPrefix}${method}`,
			params,
		})
	})
	const { result, error } = await response.json()
	if (error) throw error
	return result
}

function register(fetchUrl, data) {
	const params = [
		data.email, // email as username
		data.password,
		data.fullname,
		(data.company || data.fullname),
		data.email,
		navigator.language.slice(0, 2),
		window.location.hostname,
	]
	return send(fetchUrl, 'repo.registerNewTrial', params)
}

function getServerReplyEmail(fetchUrl) {
	return send(fetchUrl, 'getServerReplyEmail', [window.location.hostname])
}

async function onSubmit(event) {
	event.preventDefault()

	const form = event.target
	const isValid = validateForm(form)
	if (!isValid) return

	const messageContainer = form.querySelector('[data-dispatch-message]')

	const formAction = form.getAttribute('action')
	const fetchUrl = formAction.endsWith('/')
		? formAction.slice(0, formAction.length - 1)
		: formAction

	const formData = {}

	const formInputList = form.querySelectorAll('input')

	formInputList.forEach((input) => {
		input.setAttribute('disabled', true)

		if (input.type === 'submit') return
		const value = (() => {
			switch(input.type) {
				case 'text':
				case 'password': return input.value.trim()
				case 'checkbox': return input.checked
			}
		})()
		formData[input.name] = value
	})

	try {
		const result = await register(fetchUrl, formData)

		if (result === RegisterTrialResult.RepeatActivationEmail) {
			const email = await getServerReplyEmail(fetchUrl)
			showMessage(messageContainer, [
				getRepeatActivationMsg(),
				getCheckEmailReceptionMsg(email),
				getResendActivationEmailMsg(),
			].join('\n'))
		} else {
			form.reset()
			showMessage(messageContainer, getConfirmRegistrationMsg())
		}
	} catch(error) {
		const message = error.code || error.data
			? getErrorMessage(error)
			: 'Сервер не доступен'
		showError(messageContainer, message)
	} finally {
		formInputList.forEach((input) => input.removeAttribute('disabled'))
	}
}

function showMessage(container, message) {
	if (!container) {
		console.log(`%c${message}`, 'color: green')
		return
	}
	container.classList.remove(errorMessageClass)
	container.innerText = message
}

function getErrorMessage(error) {
	if (error.data?.format && error.data.format === "User '%s' exists") {
		const [ user ] = error.data?.arguments || []
		if (user) return getUserExistsMsg(user)
	}
	return error.message || 'Во время регистрации произошла ошибка'
}

function showError(container, message) {
	if (!container) {
		console.error(message)
		return
	}
	container.classList.add(errorMessageClass)
	container.innerText = message
}

forms.forEach((form) => {
	form.addEventListener('submit', onSubmit)

	form.querySelectorAll('input').forEach((input) => {
		if (input.type === 'submit') return
		input.addEventListener('change', ({ target }) => validateInput(target))
	})

	const passwordInput = form.querySelector('input[name=password]')
	const passwordRInput = form.querySelector('input[name=passwordR]')
	if (!passwordInput || !passwordRInput) {
		console.error('Password input is not found')
		return
	}
	passwordInput.addEventListener('input', ({ target }) => {
		passwordModel = target.value
		passwordRInput.setCustomValidity('')
	})
})
