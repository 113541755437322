const createPluralizer = (selectorFn) => (...variants) => (n) => {
	const s = selectorFn(n)
	return variants[s]
}

const pluralize = createPluralizer((n) => {
	if (n % 10 === 1 && n % 100 !== 11) return 0
	return n % 10 >= 2 && n % 10 <=4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2
})

const getSymbolStr = pluralize('символ', 'символа', 'символов')

const getRequiredMessage = () => 'Заполните это поле.'
const getMinLengthMessage = (length) => `Минимум ${length} ${getSymbolStr(length)}`
const getMaxLengthMessage = (length) => `Максимум ${length} ${getSymbolStr(length)}`
const getInvalidEmailMessage = () => 'Невалидный email'
const getRepeatPasswordMessage = () => 'Пароли не совпадают'

function showInputError(input, message) {
	input.setCustomValidity(message)
	input.reportValidity()
	input.addEventListener('input', () => input.setCustomValidity(''), { once: true })
}

export function validateLength(input, { min, max }) {
	const value = input.value.trim()

	if (typeof min === 'number' && value.length < min) {
		showInputError(input, getMinLengthMessage(min))
		return false
	}

	if (typeof max === 'number' && value.length > max) {
		showInputError(input, getMaxLengthMessage(max))
		return false
	}
	return true
}

export function validateRequired(input) {
	const value = input.value.trim()

	if (!value) {
		showInputError(input, getRequiredMessage())
		return false
	}
	return true
}

export function validateRepeatPassword(input, password) {
	const value = input.value.trim()

	if (value == null || value !== password.trim()) {
		showInputError(input, getRepeatPasswordMessage())
		return false
	}
	return true
}

export function validateEmail(input) {
	const value = input.value.trim()

	if (!/^\S+@\S+\.\S+$/.test(value)) {
		showInputError(input, getInvalidEmailMessage())
		return false
	}
	return true
}

export function createLengthValidator({ min, max }) {
	return (input) => validateLength(input, { min, max })
}

export function createRequiredValidator() {
	return (input) => validateRequired(input)
}

export function createEmailValidator() {
	return (input) => validateEmail(input)
}

export function formValidation(fieldParams) {

	function validateInput(input) {
		if (!input) return false

		const name = input.getAttribute('name')
		const validators = fieldParams[name] || []

		return validators.every((validator) => validator(input))
	}

	function validateForm(form) {
		if (!form) return false

		const inputList = [ ...form.querySelectorAll('input') ]

		return inputList.every((input) => {
			return validateInput(input)
		})
	}

	return {
		validateInput,
		validateForm,
	}
}